<template>
  <b-modal
    id="bv-modal-example"
    size="lg"
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    centered
    header-class="my-class"
    body-class="p-0"
  >
    <b-card class="card-custom-class">
      <div class="closemodel">
        <button @click="$bvModal.hide('bv-modal-example')" type="button">
          <span><img src="img/address/close.png" alt="" /></span>
        </button>
      </div>
      <span class="change-location-text">Change Address </span>
      <span @click="addAddress" class="add-new-address-text"
        >Add New Address
      </span>
    </b-card>
    <b-list-group class="list-group-modal">
      <b-card
        body-class="body-class-modal"
        v-for="(adderss, index) in deliveryAddress"
        :key="index"
        class="card-custom-class"
      >
        <b-row>
          <b-col cols="6">
            <b-card-text>
              <b-form-radio
                @change="changeRadio"
                :value="adderss"
                name="radio-size"
                size="lg"
                ><span class="address-name">{{ adderss.fullName }}</span>
                <span v-if="adderss.isDefault" class="default-address-class">
                  Default
                </span>
              </b-form-radio>
            </b-card-text>
          </b-col>
          <b-col cols="6">
            <span
              v-if="$store.state['address'].deleteAddressLoading != adderss._id"
              class="float-right"
            >
              <img
                @click="() => deleteAddress(adderss._id)"
                style="cursor: pointer"
                src="img/address/Trash.png"
                alt=""
              />
              <img
                class="edit-icon-style"
                @click="
                  () => {
                    editAddressClick(adderss);
                    $bvModal.show(adderss._id);
                  }
                "
                src="img/EditIcon.png"
                alt=""
              />
              <b-modal
                :id="adderss._id"
                size="lg"
                :hide-footer="true"
                :hide-header="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
                centered
                header-class="my-class"
                body-class="p-0"
              >
                <template
                  v-if="$options.components['address-edit-address-modal']"
                >
                  <address-edit-address-modal :address="adderss" />
                </template>
              </b-modal>
            </span>
            <span
              v-if="$store.state['address'].deleteAddressLoading == adderss._id"
              class="float-right"
            >
              <b-spinner
                v-for="i in 3"
                :key="i"
                class="mr-1"
                small
                label="Small Spinner"
                type="grow"
              ></b-spinner>
            </span>
          </b-col>
        </b-row>
        <b-col md="12">
          <b-card-text class="address-details-text"
            >{{ adderss.houseNumber }}, {{ adderss.street1 }},
            {{ adderss.street2 }}, {{ adderss.city }}, {{ adderss.state }},
            {{ adderss.zip }}
          </b-card-text>
        </b-col>
      </b-card>
    </b-list-group>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    deleteAddress(e) {
      this.$emit("deleteAddress", e);
    },
    editAddressClick(e) {
      this.$emit("editAddress", e);
    },
    addAddress() {
      this.$bvModal.hide("bv-modal-example");
      this.$bvModal.show("bv-add-address");
    },
    changeRadio(e) {
      this.$emit("selectedAddress", e);
      this.$bvModal.hide("bv-modal-example");
    },
  },
  props: {
    deliveryAddress: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
<style scoped>
.edit-icon-style {
  cursor: pointer;
  height: 35px;
  width: 28px;
  margin-top: 6px;
  margin-left: 10px;
}
.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}
/*  */
.list-group-modal {
  margin-top: 7px;
  max-height: 540px;
  margin-left: 10px;
  margin-right: 4px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.change-location-text {
  font-style: normal;
  font-weight: 520;
  font-size: 24px;
  line-height: 24px;
  color: #2e2e2e;
}
.add-new-address-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ff6a2e;
  cursor: pointer;
  position: absolute;
  right: 75px;
}
.address-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: #000000;
}
.body-class-modal {
  margin-top: -7px;
}

.address-details-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #2e2e2e;
  margin-top: 4px;
}

.my-class {
  background: black;
  color: white;
}
.closemodel {
  position: absolute;
  top: 8px;
  right: 3px;
}

[type="button"],
button {
  cursor: pointer;
  color: inherit;
  border: none;
  background-color: transparent;
}
.default-address-class {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #717171;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .change-location-text {
    font-size: 14px;
  }
  .add-new-address-text {
    font-size: 14px;
  }
}
</style>
